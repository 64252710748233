@use 'base';

.about-me {
    max-width: 900px;
    
    .inner {
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 50px;
        @media (max-width: 768px) {
            display: block;
        }
        p {
            margin: 20px 0px 0px;
            max-width: 540px;
            a {
                color: base.$violet;
                font-weight: 500;
            }
        }
    }
    em {
        color: base.$violet;
    }
    .fa-brands {
        color: base.$violet;
    }
    
}

.num-header {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    position: relative;
    margin: 10px 0px 40px;
    width: 100%;
    font-size: clamp(26px,5vw,base.$font-heading);
    white-space: nowrap;
    &::before {
        position: relative;
        bottom: 4px;
        counter-increment: section 1;
        content: "0" counter(section) ".";
        margin-right: 10px;
        color: base.$violet;
        font-family: base.$font-mono;
        font-size: clamp(base.$font-md,3vw,base.$font-xl);
        font-weight: 400;
    }
    &::after {
        content: "";
        display: block;
        position: relative;
        top: -5px;
        width: 300px;
        height: 1px;
        margin-left: 20px;
        background-color: base.$lightest-navy;
    }
}

.skills-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(80px, 200px));
    gap: 0px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
    li {
        position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-family: base.$font-mono;
    font-size: base.$font-xs;
    // text-align: center;
        &:before {
            content: '▹';
            position: absolute;
            left: 0;
            color: base.$violet;
            font-size: base.$font-sm;
            line-height: 12px;
        }
    }
    p {
        margin: 10px 0px 0px !important;
    }
}

.icon-text {
    font-size: base.$font-xxs;
}

// .fa-brands {
//     color: base.$violet;
// }

.fa-solid {
    color: base.$violet;
}
.skills-text {
    font-size: base.$font-lg;
}
.mypic {
    position: relative;
    width: 350px;
    height: 385.2px;
    display: block;
    & img {
        border-radius: base.$border-radius;
    }
    
    @media (max-width: 768px) {
        margin: 30px auto 0px;
        
        img {
           width: 330px;
            height: 356.4px; 
            
        }
      }
    &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: base.$border-radius;
    }
    &::after {
        border: 2px solid base.$violet;
        top: 20px;
        left: 20px;
        z-index: -1;
        
    }
    
}