@use 'base';

.opening {
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    height: 100vh;
    padding: 0px;
   
    p {
        max-width: 540px;
        margin: 20px 0px 0px !important;
       // font-size: base.$font-xl;
        //line-height: 1.6;
    }
    & h3 {
        margin-top: 5px !important;
        color: base.$slate;
        line-height: 0.9;       
    }
    & .big-heading {
        margin: 0px;
        font-size: clamp(40px, 8vw, 80px);
        
    }
    & b {
        color: base.$violet;
    }
    & div {
        align-self: center;
    }
    & .one {
        color: base.$violet;
        font-weight: 400;
        margin: 0px 0px 30px 4px;
        font-family: base.$font-mono;
        font-size: clamp(base.$font-sm,5vw,base.$font-md);
    }
    & .linkedin-link {
        color: base.$violet;
        background-color: transparent;
        border: 1px solid base.$violet;
        border-radius: base.$border-radius;
        padding: 1.25rem 1.75rem;
        font-size: base.$font-sm;
        font-family: base.$font-mono;
        line-height: 1;
        text-decoration: none;
        cursor: pointer;
        transition: base.$transition;
        margin-top: 50px;
        &:hover, &:focus-visible {
            // background-color: base.$violet-tint;
            outline: none;
            box-shadow: 4px 4px 0 0 base.$violet;
            transform: translate(-5px, -5px);
        }
    }
    
}

.social-media .email-link{
    color: #6474e5;
    background-color: transparent;
    border: 1px solid #6474e5;
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 18px;
    
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 25px;
    &:hover{
        background-color: rgba(100, 116, 229, 0.3);
    }
}

.wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
  }
  

.fas {
    color: #fff;
}