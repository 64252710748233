@charset "UTF-8";
.opening {
  display: flex;
  justify-content: center;
  -moz-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0px;
}
.opening p {
  max-width: 540px;
  margin: 20px 0px 0px !important;
}
.opening h3 {
  margin-top: 5px !important;
  color: #8892b0;
  line-height: 0.9;
}
.opening .big-heading {
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
}
.opening b {
  color: #7073FF;
}
.opening div {
  align-self: center;
}
.opening .one {
  color: #7073FF;
  font-weight: 400;
  margin: 0px 0px 30px 4px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-size: clamp(14px, 5vw, 16px);
}
.opening .linkedin-link {
  color: #7073FF;
  background-color: transparent;
  border: 1px solid #7073FF;
  border-radius: 4px;
  padding: 1.25rem 1.75rem;
  font-size: 14px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: 50px;
}
.opening .linkedin-link:hover, .opening .linkedin-link:focus-visible {
  outline: none;
  box-shadow: 4px 4px 0 0 #7073FF;
  transform: translate(-5px, -5px);
}

.social-media .email-link {
  color: #6474e5;
  background-color: transparent;
  border: 1px solid #6474e5;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: 25px;
}
.social-media .email-link:hover {
  background-color: rgba(100, 116, 229, 0.3);
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
.fas {
  color: #fff;
}

.about-me {
  max-width: 900px;
}
.about-me .inner {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
}
@media (max-width: 768px) {
  .about-me .inner {
    display: block;
  }
}
.about-me .inner p {
  margin: 20px 0px 0px;
  max-width: 540px;
}
.about-me .inner p a {
  color: #7073FF;
  font-weight: 500;
}
.about-me em {
  color: #7073FF;
}
.about-me .fa-brands {
  color: #7073FF;
}

.num-header {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0px 40px;
  width: 100%;
  font-size: clamp(26px, 5vw, 32px);
  white-space: nowrap;
}
.num-header::before {
  position: relative;
  bottom: 4px;
  counter-increment: section 1;
  content: "0" counter(section) ".";
  margin-right: 10px;
  color: #7073FF;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-size: clamp(16px, 3vw, 20px);
  font-weight: 400;
}
.num-header::after {
  content: "";
  display: block;
  position: relative;
  top: -5px;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: #233554;
}

.skills-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 200px));
  gap: 0px 10px;
  padding: 0px;
  margin: 20px 0px 0px;
  overflow: hidden;
  list-style: none;
}
.skills-list li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-size: 13px;
}
.skills-list li:before {
  content: "▹";
  position: absolute;
  left: 0;
  color: #7073FF;
  font-size: 14px;
  line-height: 12px;
}
.skills-list p {
  margin: 10px 0px 0px !important;
}

.icon-text {
  font-size: 12px;
}

.fa-solid {
  color: #7073FF;
}

.skills-text {
  font-size: 18px;
}

.mypic {
  position: relative;
  width: 350px;
  height: 385.2px;
  display: block;
}
.mypic img {
  border-radius: 4px;
}
@media (max-width: 768px) {
  .mypic {
    margin: 30px auto 0px;
  }
  .mypic img {
    width: 330px;
    height: 356.4px;
  }
}
.mypic::before, .mypic::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.mypic::after {
  border: 2px solid #7073FF;
  top: 20px;
  left: 20px;
  z-index: -1;
}

.work {
  max-width: 900px;
  margin: 0px auto;
  padding: 100px 0px;
}
.work .inner {
  min-height: 340px;
  display: flex;
}
@media (max-width: 600px) {
  .work .inner {
    display: block;
  }
}

.panels {
  position: relative;
  width: 100%;
  margin-left: 20px;
}
@media (max-width: 600px) {
  .panels {
    margin-left: 0px;
  }
}
.panels .all-panels {
  width: 100%;
  height: auto;
  padding: 10px 5px;
}
.panels .all-panels h3 {
  margin-bottom: 2px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3;
}
.panels .all-panels h3 .company {
  color: #7073FF;
}
.panels .all-panels .range {
  margin-bottom: 25px;
  color: #a8b2d1;
  font-size: 13px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-weight: 300;
}
.panels .all-panels ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  font-size: 18px;
}
.panels .all-panels ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.panels .all-panels ul li::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: #7073FF;
}

.certificates {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 10px 0px;
  max-width: 1200px;
  box-sizing: border-box;
}
.certificates .more-button {
  color: #7073FF;
  background-color: transparent;
  border: 1px solid #7073FF;
  border-radius: 4px;
  font-size: 13px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1.25rem 1.75rem;
  margin: 80px auto 0px;
}
.certificates .more-button:hover {
  background-color: rgba(112, 115, 255, 0.1);
}
.certificates .cert-list-items {
  position: relative;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}
.certificates .cert-list-items .cert-inner {
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
  display: flex;
  justify-content: space-between;
  -moz-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  border-radius: 4px;
  background-color: #112240;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: auto;
}
.certificates .cert-top .cert-links {
  margin-right: -10px;
  color: #e6f1ff;
}
.certificates .cert-top .cert-links:hover {
  color: #7073FF;
}
.certificates .cert-title {
  color: #ccd6f6;
  font-size: 18px;
}
.certificates .cert-desc {
  color: #a8b2d1;
  font-size: 18px;
  display: flex;
}
.certificates .cert-desc p {
  margin-bottom: 5px;
  margin-right: 10px;
}
.certificates .cert-skill-list {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0px;
  list-style: none;
}
.certificates .cert-skill-list ::before, .certificates .cert-skill-list ::after {
  box-sizing: border-box;
}
.certificates .cert-skill-list li {
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-size: 12px;
  line-height: 1.75;
}
.certificates .cert-skill-list li:not(:last-of-type) {
  margin-right: 15px;
}

.cert-top {
  display: inline-flex;
  -moz-box-pack: justify;
  align-items: center;
}

.cert-grid {
  list-style: none;
  padding: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 768px) {
  .cert-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .cert-grid {
    grid-template-columns: 1fr;
  }
}
.cert-grid :hover .cert-inner, .cert-grid :focus-within .cert-inner {
  transform: translateY(-7px);
}

.featured-projects {
  max-width: 1000px;
  margin: 0px auto;
  padding: 100px 0px;
}

.featured {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.featured-list {
  position: relative;
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr);
}
@media (max-width: 768px) {
  .featured-list {
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
  }
}
.featured-list:not(:last-of-type) {
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  .featured-list:not(:last-of-type) {
    margin-bottom: 70px;
  }
}
@media (max-width: 480px) {
  .featured-list:not(:last-of-type) {
    margin-bottom: 30px;
  }
}
.featured-list a {
  color: #e6f1ff;
  text-decoration: none;
}
.featured-list a:hover {
  color: #7073FF;
  text-decoration: none;
}
.featured-list .project-content {
  grid-area: 1/1/-1/7;
  position: relative;
}
@media (max-width: 1080px) {
  .featured-list .project-content {
    grid-column: 1/9;
  }
}
@media (max-width: 768px) {
  .featured-list .project-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    grid-column: 1/-1;
    padding: 40px 40px 30px;
    z-index: 5;
  }
}
@media (max-width: 480px) {
  .featured-list .project-content {
    padding: 30px 25px 20px;
  }
}
.featured-list .project-overline {
  margin: 10px 0px;
  color: #7073FF;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-size: 13px;
  font-weight: 400;
}
.featured-list .project-title {
  margin: 0px 0px 20px;
  font-weight: 600;
  font-size: clamp(24px, 5vw, 28px);
  color: #e6f1ff;
  line-height: 1.1;
}
.featured-list .project-description {
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
  position: relative;
  z-index: 2;
  padding: 25px;
  border-radius: 4px;
  background-color: #112240;
  color: #a8b2d1;
}
@media (max-width: 768px) {
  .featured-list .project-description {
    padding: 20px 0px;
    background-color: transparent;
    box-shadow: none;
  }
}
.featured-list .project-language-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  margin: 25px 0px 10px;
  padding: 0px;
  list-style: none;
}
@media (max-width: 768px) {
  .featured-list .project-language-list {
    margin: 10px 0px;
  }
}
.featured-list .project-language-list li {
  color: #ccd6f6;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  white-space: nowrap;
  margin: 0px 20px 5px 0px;
  font-size: 13px;
}
.featured-list .project-links {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  /* margin-left: -10px; */
  color: #ccd6f6;
}
.featured-list .project-image {
  box-shadow: 0 10px 30px -15px rgba(6, 16, 30, 0.7);
  /* transition: var(--transition); */
  grid-area: 1/6/-1/-1;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .featured-list .project-image {
    grid-column: 1/-1;
    height: 100%;
    opacity: 0.25;
  }
}
.featured-list .project-image img {
  border-radius: 4px;
  mix-blend-mode: multiply;
}
@media (max-width: 768px) {
  .featured-list .project-image img {
    -o-object-fit: cover;
       object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(50%);
  }
}
@media (max-width: 480px) {
  .featured-list .project-image img {
    width: 351px !important;
  }
}

.featured-list:nth-of-type(2n+1) .project-content {
  text-align: right;
  grid-column: 7/-1;
}
@media (max-width: 1080px) {
  .featured-list:nth-of-type(2n+1) .project-content {
    grid-column: 5/-1;
  }
}
@media (max-width: 786px) {
  .featured-list:nth-of-type(2n+1) .project-content {
    grid-column: 1/-1;
    padding: 40px 40px 30px;
    text-align: left;
  }
}
@media (max-width: 480px) {
  .featured-list:nth-of-type(2n+1) .project-content {
    padding: 25px 25px 20px;
  }
}
.featured-list:nth-of-type(2n+1) .project-language-list {
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .featured-list:nth-of-type(2n+1) .project-language-list {
    justify-content: flex-start;
  }
}
.featured-list:nth-of-type(2n+1) .project-language-list li {
  margin: 0px 0px 5px 20px;
}
.featured-list:nth-of-type(2n+1) .project-links {
  justify-content: flex-end;
  margin-left: 0px;
  margin-right: -10px;
  color: #ccd6f6;
}
@media (max-width: 768px) {
  .featured-list:nth-of-type(2n+1) .project-links {
    justify-content: flex-start;
    margin-left: -10px;
    margin-right: 0px;
  }
}
.featured-list:nth-of-type(2n+1) .project-links a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.featured-list:nth-of-type(2n+1) .project-image {
  grid-column: 1/8;
}
@media (max-width: 768px) {
  .featured-list:nth-of-type(2n+1) .project-image {
    grid-column: 1/-1;
  }
}
.featured-list:nth-of-type(2n+1) .project-image img {
  border-radius: 4px;
  mix-blend-mode: multiply;
}
@media (max-width: 768px) {
  .featured-list:nth-of-type(2n+1) .project-image img {
    -o-object-fit: cover;
       object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(50%);
  }
}

.project-icon {
  color: #e6f1ff;
  font-size: 24px;
}

.project-icon:hover {
  color: #6474e5;
}

.other-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 100px 0px;
  max-width: 1000px;
  box-sizing: border-box;
}
.other-projects .more-button {
  color: #7073FF;
  background-color: transparent;
  border: 1px solid #7073FF;
  border-radius: 4px;
  font-size: 13px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1.25rem 1.75rem;
  margin: 80px auto 0px;
}
.other-projects .more-button:hover {
  background-color: rgba(112, 115, 255, 0.1);
}

.github-link {
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-size: 14px;
  color: #7073FF;
}

.project-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.project-top a {
  text-decoration: none;
  color: #ccd6f6;
}

.project-grid {
  list-style: none;
  padding: 0px;
  margin: 50px 0px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  position: relative;
}
.project-grid :hover .project-inner, .project-grid :focus-within .project-inner {
  transform: translateY(-7px);
}

.more-button:hover, .more-button:focus-visible {
  outline: none;
  box-shadow: 4px 4px 0 0 #7073FF;
  transform: translate(-5px, -5px);
}

.other-list-items {
  position: relative;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}
.other-list-items .project-inner {
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
  display: flex;
  justify-content: space-between;
  -moz-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  border-radius: 4px;
  background-color: #112240;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: auto;
}
.other-list-items:hover h3 a {
  color: #7073FF;
}
.other-list-items .project-top .project-links {
  display: flex;
  align-items: center;
  margin-right: -10px;
  color: #a8b2d1;
}
.other-list-items .project-title {
  margin: 0px 0px 10px;
  color: #ccd6f6;
  font-size: 22px;
}
.other-list-items .project-title a {
  position: static;
}
.other-list-items .project-desc {
  color: #a8b2d1;
  font-size: 17px;
}
.other-list-items .project-tech-list {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
}
.other-list-items .project-tech-list ::before, .other-list-items .project-tech-list ::after {
  box-sizing: border-box;
}
.other-list-items .project-tech-list li {
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  font-size: 12px;
  line-height: 1.75;
}
.other-list-items .project-tech-list li:not(:last-of-type) {
  margin-right: 15px;
}

p:last-child, p:last-of-type {
  margin: 0px;
}

.small-menu {
  display: none;
}
@media (max-width: 768px) {
  .small-menu {
    display: block;
  }
}

.ham-button {
  display: none;
}
@media (max-width: 768px) {
  .ham-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-right: -15px;
    padding: 15px;
    border: 0px none;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    cursor: pointer;
  }
}
.ham-button:focus:not(:focus-visible) {
  outline: none;
  outline-offset: 0px;
}

.ham-button-clicked {
  display: none;
}
@media (max-width: 768px) {
  .ham-button-clicked {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-right: -15px;
    padding: 15px;
    border: 0px none;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
  }
  .ham-button-clicked:focus:not(:focus-visible) {
    outline: none;
    outline-offset: 0px;
  }
  .ham-button-clicked .nav-menu-inner {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 30px;
    height: 2px;
    border-radius: 4px;
    background-color: #7073FF;
    transition: transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(225deg);
  }
  .ham-button-clicked .nav-menu-inner::before {
    width: 100%;
    top: 0px;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    content: "";
    display: block;
    position: absolute;
    left: auto;
    right: 0px;
    width: 30px;
    height: 2px;
    border-radius: 4px;
    background-color: #7073FF;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
  }
  .ham-button-clicked .nav-menu-inner::after {
    content: "";
    display: block;
    position: absolute;
    left: auto;
    right: 0px;
    width: 30px;
    height: 2px;
    border-radius: 4px;
    background-color: #7073FF;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    width: 100%;
    bottom: 0px;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  }
}

.nav-menu {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 24px;
}

.nav-menu-inner {
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  height: 2px;
  border-radius: 4px;
  background-color: #7073FF;
  transition-duration: 0.22s;
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transform: rotate(0deg);
}
.nav-menu-inner::before {
  content: "";
  display: block;
  position: absolute;
  left: auto;
  right: 0px;
  width: 30px;
  height: 2px;
  border-radius: 4px;
  background-color: #7073FF;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
}
.nav-menu-inner::after {
  content: "";
  display: block;
  position: absolute;
  left: auto;
  right: 0px;
  width: 30px;
  height: 2px;
  border-radius: 4px;
  background-color: #7073FF;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
}

.nav-menu-inner::before {
  width: 120%;
  top: -10px;
  opacity: 1;
  transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
}

.nav-menu-inner::after {
  width: 80%;
  bottom: -10px;
  transform: rotate(0deg);
  transition: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

main.fillHeight {
  padding: 0px 150px;
}
@media (max-width: 1080px) {
  main.fillHeight {
    padding: 0px 100px;
  }
}
@media (max-width: 768px) {
  main.fillHeight {
    padding: 0px 50px;
  }
}
@media (max-width: 480px) {
  main.fillHeight {
    padding: 0px 25px;
  }
}

.side-menu {
  display: none;
}
@media (max-width: 768px) {
  .side-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 50px 10px;
    width: min(75vw, 400px);
    height: 100vh;
    outline: 0px;
    background-color: #112240;
    box-shadow: -10px 0px 30px -15px rgba(2, 12, 27, 0.7);
    z-index: 9;
    transform: translateX(100vw);
    visibility: hidden;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@media (max-width: 768px) {
  .side-menu-opened {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    padding: 50px 10px;
    width: min(75vw, 400px);
    height: 100vh;
    outline: 0px;
    background-color: #112240;
    box-shadow: -10px 0px 30px -15px rgba(2, 12, 27, 0.7);
    z-index: 9;
    transform: translateX(0vw);
    visibility: visible;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
.side-menu-opened .side-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  color: #ccd6f6;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  text-align: center;
}
.side-menu-opened .side-list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  width: 100%;
}
.side-menu-opened .side-list li {
  position: relative;
  margin: 0px auto 20px;
  counter-increment: item 1;
  font-size: clamp(14px, 4vw, 18px);
}
@media (max-width: 600px) {
  .side-menu-opened .side-list li {
    margin: 0px auto 10px;
  }
  .side-menu-opened .side-list li::before {
    content: "0" counter(item) ".";
    display: block;
    margin-bottom: 5px;
    color: #7073FF;
    font-size: 14px;
  }
}
.side-menu-opened .side-list a {
  display: inline-block;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 3px 3px 20px;
}
.side-menu-opened .side-list a:hover {
  color: #7073FF;
}

.resume-link {
  color: #7073FF;
  background-color: transparent;
  border: 1px solid #7073FF;
  border-radius: 4px;
  font-size: 14px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 18px 50px;
  margin: 10% auto 0px;
  width: -moz-max-content;
  width: max-content;
}
.resume-link:hover {
  background-color: rgba(100, 116, 229, 0.3);
}

.side-menu-opened nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  color: #ccd6f6;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  text-align: center;
}

.contact {
  max-width: 600px;
  margin: 0px auto 100px;
  text-align: center;
}
.contact .email-link {
  color: #7073FF;
  background-color: transparent;
  border: 1px solid #7073FF;
  border-radius: 4px;
  padding: 1.25rem 1.75rem;
  font-size: 14px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin: 50px;
}
.contact a {
  display: inline-block;
  position: relative;
}
.contact a:hover, .contact a:focus-visible {
  outline: none;
  box-shadow: 4px 4px 0 0 #7073FF;
  transform: translate(-5px, -5px);
}
.contact .num-header {
  font-size: 20px;
  color: #7073FF;
  justify-content: center;
}
.contact .num-header::after {
  all: revert;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 0px 50px;
  width: 93%;
  height: 100px;
  background-color: rgba(10, 25, 47, 0.85);
  filter: none !important;
  pointer-events: auto !important;
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (max-width: 1080px) {
  .nav-header {
    padding: 0px 40px;
  }
}
@media (max-width: 768px) {
  .nav-header {
    padding: 0px 25px;
  }
}
@media (max-width: 486px) {
  .nav-header {
    width: 83%;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  color: #ccd6f6;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  counter-reset: item 0;
  z-index: 12;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.nav-side {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .nav-side {
    display: none;
  }
}
.nav-side ol {
  display: flex;
  -moz-box-align: center;
  justify-content: space-between;
  -moz-box-pack: justify;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.nav-side li {
  margin: 0px 5px;
  position: relative;
  counter-increment: item 1;
  font-size: 14px;
}
.nav-side li a {
  padding: 10px;
}
.nav-side li a:hover {
  color: #7073FF;
}
.nav-side li a::before {
  content: "0" counter(item) ".";
  margin-right: 5px;
  color: #7073FF;
  font-size: 14px;
  text-align: right;
}
.nav-side .resume-button {
  color: #7073FF;
  background-color: transparent;
  border: 1px solid #7073FF;
  border-radius: 4px;
  padding: 12px 16px;
  font-family: "SF Mono", "Fira Code", "Source Code Pro", monospace;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-left: 15px;
  font-size: 13px;
}
.nav-side .resume-button:hover, .nav-side .resume-button:focus-visible {
  outline: none;
  box-shadow: 4px 4px 0 0 #7073FF;
  transform: translate(-5px, -5px);
}

*, ::before, ::after {
  box-sizing: inherit;
} /* for using seperate files, you have to import @import './header' 
make the seperate file _header.scss */
html {
  scrollbar-color: #495670 #0a192f;
}

body {
  margin: 0px;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  background-color: #0a192f;
  color: #8892b0;
  font-family: "Calibre", "Inter", "Poppins", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  counter-reset: section;
}
@media (max-width: 480px) {
  body {
    font-size: 18px;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px 0px 10px;
  font-weight: 600;
  color: #ccd6f6;
  line-height: 1.1;
}

a {
  display: inline-block;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

p {
  margin: 0px 0px 15px;
}

section {
  margin: 0px auto;
  padding: 100px 0px;
  max-width: 1000px;
}
@media (max-width: 768px) {
  section {
    padding: 80px 0px;
  }
}
@media (max-width: 480px) {
  section {
    padding: 60px 0px;
  }
}

button {
  cursor: pointer;
  border: 0px none;
  border-radius: 0px;
}

/* Main Page CSS Start */
#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* End of Main Page CSS */
/* Left and Right Side divs CSS Start*/
.social-side {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: 40px;
  right: auto;
  z-index: 10;
  color: #ccd6f6;
}
@media (max-width: 480px) {
  .social-side {
    display: none;
  }
}

.social-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.social-list li:last-of-type {
  margin-bottom: 20px;
}
.social-list li a {
  padding: 10px;
  display: inline-block;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.social-list li a:hover, .social-list li a:focus {
  color: #7073FF;
  transform: translateY(-3px);
}

.social-list::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: #ccd6f6;
  color: #a8b2d1;
}

.right-side {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 40px;
  z-index: 10;
}
@media (max-width: 480px) {
  .right-side {
    display: none;
  }
}

.back-to-top {
  background-color: #7073FF;
  border: none;
  border-radius: 15px;
  height: 60px;
  width: 50px;
  color: #fff;
  padding: 12px 16px;
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 30px;
  visibility: visible;
  box-sizing: border-box;
  cursor: pointer;
}
.back-to-top:hover {
  background-color: rgba(100, 116, 229, 0.5);
}

/* End ofLeft and Right Side divs CSS */
.inline-link {
  display: inline-block;
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #7073FF;
}
.inline-link:hover, .inline-link:focus, .inline-link:active {
  color: #7073FF;
  outline: 0;
}
.inline-link:hover:after, .inline-link:focus:after, .inline-link:active:after {
  width: 100%;
}
.inline-link:hover > *, .inline-link:focus > *, .inline-link:active > * {
  color: #7073FF !important;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.inline-link:after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  position: relative;
  bottom: 0.37em;
  background-color: #7073FF;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0.5;
}

.fadeup-enter {
  opacity: 0.01;
  transform: translateY(20px);
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fadeup-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fadedown-enter {
  opacity: 0.01;
  transform: translateY(-20px);
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fadedown-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Fade */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}/*# sourceMappingURL=style.css.map */