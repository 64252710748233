@use 'base';

.featured-projects {
    max-width: 1000px;
    margin: 0px auto;
    padding: 100px 0px;
}

.featured {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.featured-list {
    position: relative;
    -moz-box-align: center;
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    @media (max-width: 768px) {
        box-shadow: 0 10px 30px -15px base.$navy-shadow;
    }
    &:not(:last-of-type){
        margin-bottom: 100px;
        @media (max-width: 768px) {
            margin-bottom: 70px;
        }
        @media (max-width: 480px) {
            margin-bottom: 30px;
        }
    }
    & a {
        color: base.$white;
        text-decoration: none;
        &:hover {
            color: base.$violet;
            text-decoration: none;
        }
    }
    & .project-content {
        grid-area: 1 / 1 /-1 / 7;
        position: relative;
        
        @media(max-width: 1080px){
            grid-column: 1 / 9;
        }
        @media(max-width: 768px){
            display: flex;
            flex-direction: column;
            -moz-box-pack: center;
            justify-content: center;
            height: 100%;
            grid-column: 1 / -1;
            padding: 40px 40px 30px;
            z-index: 5;
        }
        @media(max-width: 480px){
            padding: 30px 25px 20px;
        }
    }
    & .project-overline {
        margin: 10px 0px;
        color: base.$violet;
        font-family: base.$font-mono; 
        font-size: 13px;
        font-weight: 400;
    }
    & .project-title {
        margin: 0px 0px 20px;
        font-weight: 600;
        font-size: clamp(24px, 5vw, 28px);
        color: base.$white;
        line-height: 1.1;
    }
    & .project-description {
        box-shadow: 0 10px 30px -15px base.$navy-shadow;
        position: relative;
        z-index: 2;
        padding: 25px;
        border-radius: 4px;
        background-color: base.$light-navy; 
        color: base.$light-slate;
        @media (max-width: 768px) {
            padding: 20px 0px;
            background-color: transparent;
            box-shadow: none;
        }
    }
    & .project-language-list {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        margin: 25px 0px 10px;
        padding: 0px;
        list-style: none;
        @media (max-width: 768px) {
            margin: 10px 0px;
        }
        li {
            color: base.$lightest-slate;
            font-family: base.$font-mono;
            white-space: nowrap;
            margin: 0px 20px 5px 0px;
            font-size: base.$font-xs;
        }
    }
    & .project-links {
        display: flex;
        -moz-box-align: center;
        align-items: center;
        position: relative;
        margin-top: 10px;
        /* margin-left: -10px; */
        color: base.$lightest-slate;
        
    }
    & .project-image {
        box-shadow: 0 10px 30px -15px rgba(6,16,30,0.7);
    /* transition: var(--transition); */
        grid-area: 1 / 6 / -1 / -1; 
        position: relative;
        z-index: 1;
        @media (max-width: 768px) {
            grid-column: 1 / -1;
            height: 100%;
            opacity: 0.25;
        }
        img {
            border-radius: base.$border-radius;
            mix-blend-mode: multiply;
            //filter: grayscale(100%) contrast(1) brightness(90%);
            @media(max-width: 768px) {
                object-fit: cover;
                width: auto;
                height: 100%;
                filter: grayscale(100%) contrast(1) brightness(50%);
            }
            @media (max-width: 480px) {
                width: 351px !important;
            }
            
        }
    }
}

.featured-list:nth-of-type(2n+1) {
    .project-content{   
        text-align: right;
        grid-column: 7 / -1;
        @media(max-width: 1080px) {
            grid-column: 5 / -1;
        }
        @media(max-width: 786px) {
            grid-column: 1 / -1;
            padding: 40px 40px 30px;
            text-align: left;
        }
        @media(max-width: 480px) {
            padding: 25px 25px 20px;
        }
    }

    & .project-language-list {
        -moz-box-pack: end;
        justify-content: flex-end;
        @media (max-width: 768px) {
            -moz-box-pack: start;
            justify-content: flex-start;
        }
        li {
            margin: 0px 0px 5px 20px;
        }
        
    }
    & .project-links {
        -moz-box-pack: end;
        justify-content: flex-end;
        margin-left: 0px;
        margin-right: -10px;
        color: base.$lightest-slate;
        @media(max-width: 768px) {
            -moz-box-pack: start;
            justify-content: flex-start;
            margin-left: -10px;
            margin-right: 0px;
        }
        a {
            display: flex;
            -moz-box-pack: center;
            justify-content: center;
            -moz-box-align: center;
            align-items: center;
            padding: 10px;
        }
    }
    & .project-image {
        grid-column:  1 / 8;
        @media (max-width: 768px) {
            grid-column: 1 / -1;
        }
        img {
            border-radius: base.$border-radius;
            mix-blend-mode: multiply;
            //filter: grayscale(100%) contrast(1) brightness(90%);
            @media(max-width: 768px) {
                object-fit: cover;
                width: auto;
                height: 100%;
                filter: grayscale(100%) contrast(1) brightness(50%);
            }
            
        }
    }
    
}

.project-icon {
    color: #e6f1ff;
    font-size: 24px;
}
.project-icon:hover {
    color: #6474e5;
}