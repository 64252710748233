@use 'base';

.contact {
    max-width: 600px;
    margin: 0px auto 100px;
    text-align: center;
    .email-link {
        color: base.$violet;
        background-color: transparent;
        border: 1px solid base.$violet;
        border-radius: base.$border-radius;
        padding: 1.25rem 1.75rem;
        font-size: base.$font-sm;
        font-family: base.$font-mono;
        line-height: 1;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
        margin: 50px;
    }
    & a {
        display: inline-block;
        position: relative;
        &:hover, &:focus-visible {
            // background-color: base.$violet-tint;
            outline: none;
            box-shadow: 4px 4px 0 0 base.$violet;
            transform: translate(-5px, -5px);
        }
    }
    & .num-header{
        font-size: base.$font-xl;
        color: base.$violet;
        
        justify-content: center;
        &::after {
            all:revert;
        }
    }
    
}