$navy : #0a192f;
$dark-navy : #020c1b;
$light-navy : #112240;
$lightest-navy: #233554;
$navy-shadow : rgba(2,12,27,0.7);
$dark-slate : #495670;
$slate: #8892b0;
$light-slate: #a8b2d1;
$lightest-slate: #ccd6f6;
$white : #e6f1ff;
$light-violet: #95A0F3;
$violet : #7073FF;
$violet-tint :  rgba(112,115,255, 0.1);
$font-sans : 'Calibre', 'Inter', 'Poppins', sans-serif;
$font-mono : 'SF Mono', 'Fira Code', 'Source Code Pro', monospace;
$font-xxs: 12px;
$font-xs: 13px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-xxl: 22px;
$font-heading: 32px;
$border-radius: 4px;
$nav-height: 100px;
$nav-scroll-height : 70px;
$tab-height: 42px;
$tab-width: 120px;
$easing : cubic-bezier(0.645,0.045,0.355,1);
$transition : all 0.25s cubic-bezier(0.645,0.045,0.355,1);
$ham-before: top 0.1s ease-in 0.25s,opacity 0.1s ease-in;
$ham-before-active: top 0.1s ease-out,opacity 0.1s ease-out 0.12s;
$ham-after: bottom 0.1s ease-in 0.25s,transform 0.22s cubic-bezier(0.55,0.055,0.675,0.19);
$ham-after-active: bottom 0.1s ease-out,transform 0.22s cubic-bezier(0.215,0.61,0.355,1) 0.12s;
