@use 'base';
@import './opening'; 
@import './about';
@import './experience';
@import './certificates';
@import './featured';
@import './projects';
@import './hammenu';
@import './contact';
@import './navheader';


*, ::before, ::after {
    box-sizing: inherit;
}/* for using seperate files, you have to import @import './header' 
make the seperate file _header.scss */
html {
    scrollbar-color: base.$dark-slate base.$navy;
}
body {
    margin: 0px;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background-color: base.$navy;
    color: base.$slate;
    font-family: base.$font-sans;
    font-size: base.$font-xl;
    line-height: 1.5;
    counter-reset: section;

    @media (max-width: 480px) {
        font-size: base.$font-lg;
    }
}
h1, h2, h3, h4, h5, h6 {
    margin: 0px 0px 10px;
    font-weight: 600;
    color: base.$lightest-slate;
    line-height: 1.1;
}

a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    transition: base.$transition;
    
}

p {
    margin: 0px 0px 15px;
}

section {
    margin: 0px auto;
    padding: 100px 0px;
    max-width: 1000px;
    @media (max-width: 768px) {
        padding: 80px 0px;
    }
    @media (max-width: 480px) {
        padding: 60px 0px;
    }
    
}

button {
    cursor: pointer;
    border: 0px none;
    border-radius: 0px;   
}
/* Main Page CSS Start */
#root {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* End of Main Page CSS */




/* Left and Right Side divs CSS Start*/
.social-side {
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: 40px;
    right: auto;
    z-index: 10;
    color: base.$lightest-slate;
    @media (max-width : 480px) {
        display: none;
    }
}

.social-list {
    display: flex;
    flex-direction: column;
    -moz-box-align: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    list-style: none;
    
    li {
        &:last-of-type {
            margin-bottom: 20px;
        }
     a {
        padding: 10px;
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        position: relative;
        transition: base.$transition;
        &:hover,
        &:focus {
            color: base.$violet;
            transform: translateY(-3px);
        }        
        }
    }
    
}

.social-list::after {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: #ccd6f6;
    color: #a8b2d1;
}

.right-side {
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: auto;
    right: 40px;
    z-index: 10;
    @media (max-width : 480px) {
        display: none;
    }
}

.back-to-top {
    background-color: base.$violet;
    border: none;
    border-radius: 15px;
    height: 60px;
    width: 50px;
    color: #fff;   
    padding: 12px 16px;
    position: fixed;
    z-index: 99;
    bottom: 20px;
    right: 30px;
    visibility: visible;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
        background-color: rgba(100, 116, 229, 0.5);
    }
}
/* End ofLeft and Right Side divs CSS */

.inline-link {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: base.$transition;
    color: base.$violet;
    &:hover,
    &:focus,
    &:active {
      color: base.$violet;
      outline: 0;
      &:after {
        width: 100%;
      }
      & > * {
        color: base.$violet !important;
        transition: base.$transition;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      position: relative;
      bottom: 0.37em;
      background-color: base.$violet;
      transition: base.$transition;
      opacity: 0.5;
    }
}



.fadeup-enter {
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity 300ms base.$easing, transform 300ms base.$easing;
  }

  .fadeup-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms base.$easing, transform 300ms base.$easing;
  }

  .fadedown-enter {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: opacity 300ms base.$easing, transform 300ms base.$easing;
  }

  .fadedown-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms base.$easing, transform 300ms base.$easing;
  }

  /* Fade */
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms base.$easing;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
      opacity: 0;
      transition: opacity 300ms base.$easing;
  }