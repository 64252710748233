@use 'base';

.nav-header {
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 11;
    padding: 0px 50px;
    width: 93%;
    height: base.$nav-height;
    background-color: rgba(10, 25, 47, 0.85);
    filter: none !important;
    pointer-events: auto !important;
    user-select: auto !important;
    backdrop-filter: blur(10px);
    transition: base.$transition;
   // box-shadow: 0 10px 30px -10px base.$navy-shadow;
    
    @media (max-width: 1080px) {
        padding: 0px 40px;
    }
    @media (max-width: 768px) {
        padding: 0px 25px;
    }
    @media (max-width: 486px) {
        width: 83%;
    }

}

.nav-header-scroll-down {

}


// @media (prefers-reduced-motion: no-preference) {
//     .nav-header {
//         height: base.$nav-scroll-height;
//         transform: translateY(calc(base.$nav-scroll-height * -1));
//         box-shadow: 0 10px 30px -10px base.$navy-shadow;
//     }
// }

.nav {
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    color: base.$lightest-slate;
    font-family: base.$font-mono;
    counter-reset: item 0;
    z-index: 12;
    @media (max-width: 786px) {
        
    }
}
.logo {
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    vertical-align: middle;
}

.nav-side {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    @media (max-width: 768px) {
        display: none;
    }

    ol {
        display: flex;
        -moz-box-align: center;
        justify-content: space-between;
        -moz-box-pack: justify;
        
        align-items: center;
        padding: 0px;
        margin: 0px;
        list-style: none;
    }
    & li {
        margin: 0px 5px;
        position: relative;
        counter-increment: item 1;
        font-size: base.$font-sm;
        
        a {
            padding:  10px;
            
        }
        & a:hover {
            color: base.$violet;
        }
        & a::before {
            content: '0' counter(item) '.';
            margin-right: 5px;
            color: base.$violet;
            font-size: base.$font-sm;
            text-align: right;
        }
    }
   
    & .resume-button{
        color: base.$violet;
        background-color: transparent;
        border: 1px solid base.$violet;
        border-radius: base.$border-radius;
        padding: 12px 16px;
        font-family: base.$font-mono;
        line-height: 1;
        text-decoration: none;
        cursor: pointer;
        transition: base.$transition;
        margin-left: 15px;
        font-size: base.$font-xs;
        &:hover, &:focus-visible {
        // background-color: base.$violet-tint;
        outline: none;
        box-shadow: 4px 4px 0 0 base.$violet;
        transform: translate(-5px, -5px);
    }
    }
    
}